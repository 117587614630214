import { useEffect } from "react"

export default function TingDisclaimer() {
  useEffect(() => {
  }, [])

  return (
    <div className="-oneX-row">
      <div id="th-disclosures-and-disclaimers" className="-oneX-disclosures-and-disclaimers-open pt-5">
        <div className="-oneX-disclosures-and-disclaimers-body-open -oneX-body--tertiary">
          <b>DISCLAIMERS</b>
          <ol>
            <li>
              To be eligible for this offer, you must have a State Farm non-tenant homeowners, condominium unit owners, manufactured home, or farm and ranch policy
              from State Farm Fire and Casualty Company, State Farm General Insurance Company, State Farm Lloyds or State Farm Florida Insurance Company. Limited
              supplies in participating states.
            </li>
            <li>
              Ting detects arcing signals caused by damaged wires, loose connections, or faulty devices that are often precursors to electrical fires. Our Ting Support
              Team will notify you through a phone call, text, email, and/or App notification if Ting identifies a hazard. If needed, a Ting-certified, licensed electrician
              will be dispatched to help find and fix the hazard, with your $1,000 credit at the ready. See Ting Terms of Service for details.
            </li>
          </ol>
          <p>State Farm, the State Farm Logo, and Like a good neighbor, State Farm is there are registered trademarks of State Farm Mutual Automobile Insurance Company.</p>
        </div>
      </div>
    </div>
  )
}
