/**
 * Util function to send log events to lambda
*/
import { v4 as uuidv4 } from "uuid"
import { oktaTokenExpiresBefore, getOktaToken } from "./session-management.js"

const spaRequestId = uuidv4()

const logger = function (...args) {
  let authToken = ""
  // Prioritize Okta
  if (!oktaTokenExpiresBefore(0)) { // If the Okta token has expired
    const oktaToken = getOktaToken()
    authToken = oktaToken !== undefined && oktaToken.length > 0 ? `Bearer ${oktaToken}` : ""
  }

  // Don't attempt logger without an authorizationToken
  if (authToken === undefined || authToken.length === 0) return
  let message = ""
  // Concatenate all of the arguments into a message string
  for (let index = 0; index < args.length; index++) {
    const arg = args[index]
    if (typeof arg === "object" && arg !== null) {
      // stringify objects
      message += `${JSON.stringify(arg)} `
    } else {
      message += `${arg} `
    }
  }
  const event = JSON.stringify({ spaRequestId, message })
  try {
    fetch(`${process.env.REACT_APP_TING_API_URL}/logger`, {
      body: event,
      headers: {
        "Content-Type": "application/json",
        "authorizationToken": authToken,
        "x-sf-ting-spaRequestId": spaRequestId
      },
      method: "POST"
    })
  } catch (error) {
    console.log("logger error: ", error)
  }
}

export const getSpaRequestId = () => spaRequestId

export default logger
