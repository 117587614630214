
import logger, { getSpaRequestId } from "../utils/logger.js"
import sleeper from "../utils/sleep-promise.js"
import { refreshOktaToken, getOktaToken } from "../utils/session-management.js"

const spaRequestId = getSpaRequestId()

export async function accept(residenceData, tries = 1) {
  let authToken
  if (await refreshOktaToken()) { // Refresh the token right before sending it downstream
    authToken = `Bearer ${getOktaToken()}`
  } else {
    throw new Error("Okta token refresh failed")
  }

  try {
    const reqBody = {
      customer_id: residenceData.customerId,
      acceptance_date: new Date().toISOString(),
      pa_version: process.env.REACT_APP_TING_PARTICIPANT_AGREEMENT_VERSION,
      sf_enrollment_ids: residenceData.residences.map(
        (residence) => residence.enrollmentId.toString()
      )
    }
    logger("PA2 accept started", { reqBody })
    const res = await fetch(
      `${process.env.REACT_APP_TING_API_URL}/pa/acceptance`,
      {
        headers: {
          "Content-Type": "application/json",
          "authorizationToken": authToken,
          "x-sf-ting-spaRequestId": spaRequestId
        },
        method: "POST",
        body: JSON.stringify(reqBody)
      }
    ).catch((error) => {
      logger("PA2 accept error:", { error })
      res.status(500).json({ response: "Something Went Wrong." })
      res.end()
    })

    if (res.status !== 200 && res.status !== 401 && res.status !== 403
        && tries < process.env.REACT_APP_MAX_ACCEPT_TRIES
    ) {
      // Wait time to try again gets incrementally longer
      await sleeper(tries * process.env.REACT_APP_ACCEPT_RETRY_WAIT_INTERVAL)
      // Recursively call accept() up until REACT_APP_MAX_ACCEPT_TRIES when there's an error
      logger(`PA2 accept 5xx error retry #${tries}`, { status: res.status })
      return accept(residenceData, tries + 1)
    }
    logger("PA2 accept status: ", res.status)
    return res
  } catch (error) {
    if (tries < process.env.REACT_APP_MAX_ACCEPT_TRIES) {
      // Wait time to try again gets incrementally longer
      await sleeper(tries * process.env.REACT_APP_ACCEPT_RETRY_WAIT_INTERVAL)
      // Recursively call accept() up until REACT_APP_MAX_ACCEPT_TRIES when there's an error
      logger(`PA2 accept error retry #${tries}`, { error })
      return accept(residenceData, tries + 1)
    }
    return {
      timedOut: true
    }
  }
}
