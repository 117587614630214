const SupportEmailLink = ({ subject, ...props }) => {
  const supportEmail = process.env.REACT_APP_TING_SUPPORT_URL
  const subjectLine = `${subject}`
  const encodedEmail = encodeURIComponent(`"State Farm Ting Support" <${supportEmail}>`)
  const encodedSubject = encodeURIComponent(subjectLine)
  const mailtoLink = `mailto:${encodedEmail}?subject=${encodedSubject}`

  return (
    <a href={mailtoLink} className="-oneX-link--inline" style={{ display: "inline" }} {...props}>State Farm Ting Support Team</a>
  )
}

export default SupportEmailLink
