import { useEffect, useState } from "react"
import FullScreenSpinner from "../../components/media/FullScreenSpinner"
import AcceptanceForm from "../../components/AcceptanceForm"
import { useNavigate } from "react-router-dom"
import { isOktaTokenAvailable, getOktaToken, isSSOTokenAvailable, refreshSession, refreshOktaToken, removeAuthCookies, oktaTokenExpiresBefore } from "../../utils/session-management.js"
import logger from "../../utils/logger.js"
import { getResidences } from "../../api/getResidences.js"
import setAnalyticsData from "../../utils/adobe.js"

function Acceptance() {
  const [isLoading, setIsLoading] = useState(true)
  const [residencesRespBody, setResidencesResponse] = useState({})

  const navigate = useNavigate()

  const INELIGIBLE_MESSAGES = [
    "NO_TING_RESIDENCES",
    "NO_ACTIVATIONS_ELIGIBLE",
    "ALL_ELIGIBLE_ACCEPTED",
    "ALL_CANCELLED"
  ]

  const isEligible = (residencesRespBody) => {
    const bodyMsg = residencesRespBody?.message
    if (!bodyMsg || bodyMsg.length === 0) {
      return true
    }
    const hasMatch = INELIGIBLE_MESSAGES.some((ineligMsg) => bodyMsg.includes(ineligMsg))
    return !hasMatch
  }

  const redirectToProofing = () => {
    removeAuthCookies()
    const proofingURL = `${process.env.REACT_APP_AUTH_URL}?goto=${window.location.href}`
    window.location.href = proofingURL
  }

  // Periodically attempt to refresh the Okta token
  const monitorOkta = () => (setInterval(() => {
    const otkaTokenExpiresSoon = oktaTokenExpiresBefore(process.env.REACT_APP_OKTA_TOKEN_EXPIRATION_BUFFER_SECONDS)
    if (otkaTokenExpiresSoon) {
      refreshOktaToken()
    }
  }, process.env.REACT_APP_OKTA_TOKEN_REFRESH_INTERVAL_SECONDS * 1000))

  useEffect(() => {
    document.title = "Ting Participant Agreement"

    // Load 1x Script
    const oneXScript = document.createElement("script")
    oneXScript.src = `${process.env.REACT_APP_ONEX_URL}/en_US/dxl-1x/prod/js/1x.core.js`
    document.body.appendChild(oneXScript)

    setAnalyticsData({
      screenId: "ting-pa-acceptance",
      screenChannel: "ting"
    })

    const fetchData = async () => {
      setIsLoading(true)
      try {
        // sso tokens are no longer being used for customers
        if (isSSOTokenAvailable() && !isOktaTokenAvailable()) {
          // remove the sso token and redirect to '/pa/error'
          removeAuthCookies()
          navigate("/pa/error")
          return
        }

        if (isOktaTokenAvailable()) {
          const isValidSession = await refreshSession()
          if (!isValidSession) {
            redirectToProofing()
            return
          }

          monitorOkta()

          const loggerData = {
            oktaToken: getOktaToken()
          }
          logger("PA2 Acceptance fetchData() getResidences initiated", loggerData)

          const residencesResponse = await getResidences()
          const residencesResponseStatus = await residencesResponse.status

          if (residencesResponseStatus === 200) {
            const residencesRespBody = await residencesResponse.json()
            loggerData.residencesRespBody = residencesRespBody
            if (!isEligible(residencesRespBody)) {
              logger("PA2 Acceptance fetchData() getResidences ineligible redirecting to /pa/ineligible", loggerData)
              navigate("/pa/ineligible")
            } else {
              logger("PA2 Acceptance fetchData() getResidences eligible", loggerData)
              setResidencesResponse(residencesRespBody)
              console.log("setting residences response")
              setIsLoading(false)
            }
          } else if (residencesResponseStatus === 401 || residencesResponseStatus === 403) {
            loggerData.residencesResponseStatus = residencesResponseStatus
            loggerData.residencesResponseStatusText = residencesResponse.statusText
            logger("PA2 Acceptance fetchData() getResidences 401/403 redirecting to proofing", loggerData)
            redirectToProofing()
            return
          } else {
            loggerData.residencesResponseStatus = residencesResponseStatus
            loggerData.residencesResponseStatusText = residencesResponse.statusText
            logger("PA2 Acceptance fetchData() getResidences error redirecting to /pa/error", loggerData)
            navigate("/pa/error")
          }
        } else {
          redirectToProofing()
          return
        }
      } catch (ex) {
        console.log(`error occurred in PA landing: ${ex}`)
        navigate("/pa/error")
      }
      console.log("fetchData ended")
    }
    fetchData()
  }, [])

  return (
    <div className="main-container">
      <header data-type="1x-header-auth"></header>
      <main>
        {isLoading ? (
          <FullScreenSpinner/>
        ) : (
          <AcceptanceForm data={residencesRespBody}/>
        )}
      </main>
      <footer data-type="1x-footer"></footer>
    </div>
  )
}

export default Acceptance
