import { useEffect } from "react"
import ChatBot from "../../components/Chatbot"
import SFTingLogo from "../../components/media/SFTingLogo"
import setAnalyticsData from "../../utils/adobe.js"
import logger from "../../utils/logger.js"

function Ineligible() {
  logger("PA2 Ineligible page")

  useEffect(() => {
    document.title = "Ting Participant Agreement"

    // Load 1x Script
    const oneXScript = document.createElement("script")
    oneXScript.src = `${process.env.REACT_APP_ONEX_URL}/en_US/dxl-1x/prod/js/1x.core.js`
    document.body.appendChild(oneXScript)

    setAnalyticsData({
      screenId: "ting-pa-ineligible",
      screenChannel: "ting"
    })
  }, [])

  function clickSupportPAIneligible() {
  }

  return (
    <div className="main-container">
      <header data-type="1x-header-auth"></header>
      <main>
        <div className="-oneX-container">
          <div className="-oneX-row pb-5">
            <div className="-oneX-col-8">
              <h1 className="-oneX-heading-h1" style={{ color: "#D62311" }}>Thank you for your interest in the Ting program!</h1>
              <p className="-oneX-body--intro-sm">It appears you have already accepted the updated Participant Agreement, or you are not eligible for the Ting program at this time.</p>
              <p className="-oneX-body--intro-sm">To be eligible for this offer, a named insured must have an active State Farm non-tenant homeowners, condominium unit owners, manufactured home, or farm ranch policy.</p>
              <p className="-oneX-body--intro-sm">If you feel you are receiving this message in error, please email your name and property address to the <a href="mailto:State%20Farm%20Ting%20Support%20%3chome.fire-tingtelematics.664i00@statefarm.com%3e?subject=SF%20Ting%20Participant%20Agreement" onClick={clickSupportPAIneligible.bind(this)} className="-oneX-link--inline">State Farm Ting Support Team</a>.</p>
            </div>
            <div className="-oneX-col-1">
            </div>
            <div className="-oneX-col-1 -oneX-d-lg-block" style={{ "margin-top": "auto" }}>
              <SFTingLogo size={"m"}/>
            </div>
          </div>
          <ChatBot/>
        </div>
      </main>
      <footer data-type="1x-footer"></footer>
    </div>
  )
}

export default Ineligible
