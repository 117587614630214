import { useEffect } from "react"
import SFTingLogo from "../../components/media/SFTingLogo"
import ChatBot from "../../components/Chatbot"
import setAnalyticsData from "../../utils/adobe.js"
import logger from "../../utils/logger.js"

function Success() {
  logger("PA2 Success page")

  useEffect(() => {
    document.title = "Ting Participant Agreement"

    // Load 1x Script
    const oneXScript = document.createElement("script")
    oneXScript.src = `${process.env.REACT_APP_ONEX_URL}/en_US/dxl-1x/prod/js/1x.core.js`
    document.body.appendChild(oneXScript)

    setAnalyticsData({
      screenId: "ting-pa-success",
      screenChannel: "ting"
    })
  }, [])

  function clickSupportPASuccess() {
  }

  return (
    <div className="main-container">
      <header data-type="1x-header-auth"></header>
      <main>
        <div className="-oneX-container">
          <div className="-oneX-row pb-5">
            <div className="-oneX-col-8">
              <h1 className="-oneX-heading-h1" style={{ color: "#D62311" }}>Success!</h1>
              <p className="-oneX-body--intro-sm">Your acceptance of the updated Participant Agreement has been received. The Ting home monitoring service will continue courtesy of State Farm.</p>
              <p className="-oneX-body--intro-sm">You can close this page. You can also return to the <a href={process.env.REACT_APP_TING_FIRE_SAFETY} onClick={clickSupportPASuccess.bind(this)} className="-oneX-link--inline">State Farm Ting information page</a>.</p>
            </div>
            <div className="-oneX-col-1">
            </div>
            <div className="-oneX-col-1 -oneX-d-lg-block pt-5" style={{ "margin-top": "auto" }}>
              <SFTingLogo size={"m"}/>
            </div>
          </div>
        </div>
        <ChatBot/>
      </main>
      <footer data-type="1x-footer"></footer>
    </div>
  )
}

export default Success
