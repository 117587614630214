import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import styles from "../styles/Home.module.css"
import logger from "../utils/logger.js"
import states from "../utils/states.json"
import setAnalyticsData from "../utils/adobe.js"
import ChatBot from "../components/Chatbot"
import SupportEmailLink from "../components/SupportEmail"

function Ineligible() {
  logger("Ineligible page")

  const [isLoading, setIsLoading] = useState(true)
  let { stateData } = useLocation()

  const ineligibleStates = states

  const supportEmailSubject = "SF Ting Enrollment Ineligible"

  useEffect(() => {
    // Load 1x Script
    const oneXScript = document.createElement("script")
    oneXScript.src = `${process.env.REACT_APP_ONEX_URL}/en_US/dxl-1x/prod/js/1x.core.js`
    document.body.appendChild(oneXScript)

    stateData = stateData || {}
    setAnalyticsData({
      screenId: "ting-enrollment-ineligible",
      screenChannel: "ting",
      clientID: stateData.customerId || ""
    })

    setIsLoading(false)
  }, [])

  return (
    <div className="main-container">
      <head>
        <title>Ting Enrollment - Ineligible</title>

        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossOrigin="anonymous" />
        <link rel="stylesheet" href={`${process.env.REACT_APP_ONEX_URL}/en_US/dxl-1x/prod/css/lib/normalize.css`} />
        <link rel="stylesheet" href={`${process.env.REACT_APP_ONEX_URL}/en_US/dxl-1x/prod/css/1x.core.css`} type="text/css" />
      </head>

      <header data-type="1x-header-auth"></header>

      <main className={styles.main}>
        <div className="-oneX-container">
          <div className="-oneX-row pb-5">
            <div className="-oneX-col-12">
              <h1>Thank you for your interest in the Ting program!</h1>
              <p>It appears you have already enrolled all eligible policies, or you are not eligible for the Ting program at this time.</p>
              <p>To be eligible for this offer, a policyholder must have an active State Farm non-tenant homeowners, condominium unit owners, manufactured home, or farm and ranch policy.</p>
              {isLoading ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <p>The Ting program is currently unavailable in {ineligibleStates.map((state, index) =>
                  <span key={`${state}-${index}`}>{(ineligibleStates.length === (index + 1) ? `and ${state}.` : `${state}, `)}</span>
                )}</p>
              )
              }
              <p>If you feel you are receiving this message in error, please email your name and property address to the <SupportEmailLink subject={supportEmailSubject}/> for assistance.</p>
            </div>
          </div>
          <ChatBot/>
        </div>
      </main>

      <footer data-type="1x-footer"></footer>
      <script rel="preload" src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossOrigin="anonymous"></script>
      <script rel="preload" src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossOrigin="anonymous"></script>
    </div>
  )
}

export default Ineligible
