export default function EligibleAddress({ onSelectedAddress, index, address }) {
  function handleAddressSelection() {
    onSelectedAddress(document.querySelector(".eligible-address-item input[type=radio]:checked").value)
  }

  return (
    <div className="-oneX-radio-item eligible-address-item">
      <input onClick={handleAddressSelection.bind(this)} id={`eligibleAddress${index}`} type="radio" value={index} defaultChecked={index === 0} className="-oneX-radio" name="group8"/>
      <label htmlFor={`eligibleAddress${index}`}>{`${address.addressLine1 + (address.addressLine2 ? ` ${address.addressLine2}` : "")}, ${address.city}, ${address.state} ${address.zip}`}</label>
    </div>
  )
}
