import TermsAndConditionsDefault from "./TermsAndConditionsDefault.js"

export default function TermsAndConditions() {
  function checkForEmptyFields() {
    if (document.getElementById("firstName").value.trim().length > 0 &&
        document.getElementById("lastName").value.trim().length > 0 &&
        document.getElementById("email").value.trim().length > 0 &&
        document.getElementById("email").value.trim().match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) &&
        document.getElementById("phone").value.trim().replace(/[^0-9]/g, "")
          .toString().length === 10 &&
        (document.getElementById("sameAsEligibleAddress")?.checked ||
          (document.getElementById("shipping-address_line1-id")?.value &&
          document.getElementById("shipping-state-id")?.value &&
          document.getElementById("shipping-city-id")?.value &&
          document.getElementById("shipping-zip-id")?.value)
        ) &&
        document.getElementById("termsAndConditionsCheckbox").checked) {
      document.getElementById("submitButton").disabled = false
    } else {
      document.getElementById("submitButton").disabled = true
    }
  }

  function clickProgramTerms() {
  }

  return (
    <div className="-oneX-terms-and-conditions" id="terms-and-conditions">
      <TermsAndConditionsDefault/>
      <div className="-oneX-terms-and-conditions-checkbox">
        <input id="termsAndConditionsCheckbox" type="checkbox" className="-oneX-checkbox" onInput={checkForEmptyFields.bind(this)} onClick={clickProgramTerms.bind(this)}/>
        <label htmlFor="termsAndConditionsCheckbox">I have read, understand, and agree with the program terms above.</label>
      </div>
    </div>
  )
}
