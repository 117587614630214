const setAnalyticsData = function (data) {
  // Examples of what should be in data:
  // data.screenId = 'game-end-winner-' + winner
  // data.screenChannel = 'game'
  if (data) {
    for (const name in data) {
      window.dataLayer[name] = data[name]
    }
    createAnalyticsEvent()
  }
}

const createAnalyticsEvent = function () {
  let ev
  if (typeof Event === "function") {
    ev = new Event("screenChange", { bubbles: true, cancelable: false })
  } else {
    ev = document.createEvent("Event")
    ev.initEvent("screenChange", true, false)
  }
  window.setTimeout(() => {
    window.dispatchEvent(ev)
    console.log("AE dispatched")
  }, 250)
}

export default setAnalyticsData
