import { useEffect, useState } from "react"
import mediumImage from "../../media/new-ting-M.jpg"
import xlImage from "../../media/new-ting-XL.jpg"

export default function SFTingLogo({ size = "m" }) {
  const [imageRef, setImageRef] = useState("")

  useEffect(() => {
    switch (size) {
    case "m":
      setImageRef(mediumImage)
      break
    case "xl":
      setImageRef(xlImage)
      break
    default:
      setImageRef(mediumImage)
    }
  }, [size])

  return (
    <img
      src={imageRef}
      alt="Logos of State Farm and Ting. Iphone with screen shot of the Ting app sits next to the Ting device.">
    </img>
  )
}
