import { useLayoutEffect } from "react"

const ChatBot = () => {
  useLayoutEffect(() => {
    const chatButton = document.createElement("script")
    chatButton.id = "chatButton"
    chatButton.type = "module"
    chatButton.src = process.env.REACT_APP_SF_CHATBOT_CHAT_BUTTON_JS
    chatButton.crossOrigin = "anonymous"
    chatButton.setAttribute("data-configKey", "DigitalExperienceChatbot")
    chatButton.setAttribute("data-customerName", "Neighbor")
    chatButton.setAttribute("data-customerFacing", "true")
    chatButton.setAttribute("data-unauthenticatedUser", "true")
    chatButton.setAttribute("data-additionalAttributes", JSON.stringify({
      contactStartPage: window.location.href,
      experience: "ting"
    }))
    document.head.appendChild(chatButton)

    const chatStyles = document.createElement("link")
    chatStyles.rel = "stylesheet"
    chatStyles.href = process.env.REACT_APP_SF_CHATBOT_CHAT_STYLING
    document.head.appendChild(chatStyles)

    const chatScript = document.createElement("script")
    chatScript.type = "module"
    chatScript.src = process.env.REACT_APP_SF_CHATBOT_CHAT_JS
    chatScript.crossOrigin = "anonymous"
    document.body.appendChild(chatScript)

    // Cleanup on component unmount
    return () => {
      document.head.removeChild(chatButton)
      document.body.removeChild(chatScript)
      document.head.removeChild(chatStyles)
    }
  }, [])

  return null
}

export default ChatBot
