/*
Fetches the data from IOT Eligibility API
*/

import sleeper from "../utils/sleep-promise.js"
import logger from "../utils/logger.js"
import { refreshOktaToken, getOktaToken } from "../utils/session-management.js"

export async function getUserEligibility(cmpid, tries = 1) {
  const headers = {}
  headers["Content-Type"] = "application/json"
  headers["Cache-Control"] = "private, max-age=0, must-revalidate"

  // See https://sfgitlab.opr.statefarm.org/pathfinder/iot-eligibility-api/-/blob/develop/doc/development/ConsumerMigration/ConsumerMigrationToAWS.md#calling-ioteligibilityapi
  if (process.env.REACT_APP_SF_TEST_LANE) {
    headers["SF-Lane"] = process.env.REACT_APP_SF_TEST_LANE
  }

  // Prioritize Okta
  if (await refreshOktaToken()) { // Refresh the token right before sending it downstream
    const oktaToken = getOktaToken()
    headers.Authorization = oktaToken !== undefined && oktaToken.length > 0 ? `Bearer ${oktaToken}` : ""
  } else {
    throw new Error("Okta token refresh failed")
  }

  try {
    const res = await fetch(`${process.env.REACT_APP_ELIGIBILITY_URL}`,
      {
        headers: headers,
        method: "GET"
      }
    ).catch((error) => {
      logger("getUserEligibility fetch error", error)
      res.status(500).json({ response: "Something Went Wrong." })
      res.end()
    })

    if (res.status === 409) {
      res.badResponse = true
      return res
    } else if (res.status !== 200 && res.status !== 401 && res.status !== 403) {
      if (tries < process.env.REACT_APP_MAX_ENROLLMENT_TRIES) {
        // Wait time to try again gets incrementally longer
        await sleeper(tries * process.env.REACT_APP_ENROLLMENT_RETRY_WAIT_INTERVAL)
        // Recursively call getUserEligibility() up until REACT_APP_MAX_ENROLLMENT_TRIES when there's an error
        logger(`getUserEligibility 5xx error retry #${tries}`)
        return getUserEligibility(cmpid, tries + 1)
      }
      res.timedOut = true
    }
    return res
  } catch (error) {
    if (tries < process.env.REACT_APP_MAX_ENROLLMENT_TRIES) {
      // Wait time to try again gets incrementally longer
      await sleeper(tries * process.env.REACT_APP_ENROLLMENT_RETRY_WAIT_INTERVAL)
      // Recursively call getUserEligibility() up until REACT_APP_MAX_ENROLLMENT_TRIES when there's an error
      logger(`getUserEligibility error retry #${tries}`)
      return getUserEligibility(cmpid, tries + 1)
    }
    return {
      timedOut: true
    }
  }
}
